import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import {
    Collapse, Header, HeaderBrand, HeaderContent, HeaderRightZone, Headers, HeaderToggler, Icon, Nav, NavItem
    , NavLink, ModalFooter, ModalBody, ModalHeader, Modal, Button, Megamenu, MegamenuItem, LinkList, LinkListItem, Col, Row
} from 'design-react-kit';
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'typeface-lora';
import 'typeface-roboto-mono';
import 'typeface-titillium-web';
import logo from '../../img/regione-sicilia-logo.svg';
import { UserAvatar } from '../custom/UserAvatar';
import { useKeycloak } from "@react-keycloak/web";

function CustomHeader({ isPageChanged, setIsPageChanged }) {
    const location = useLocation();
    const navigate = useNavigate();
    const [isRequestedATabChange, toggleModalTabChange] = useState(false);

    const { keycloak } = useKeycloak();

    const [navigateTo, setNavigateTo] = useState(null);
    const [collapse, setCollapse] = useState(false);

    const toggle = () => {
        setCollapse(!collapse);
    };

    return (
        <Headers>
            <div className="it-nav-wrapper">

                <Header
                    small
                    theme=""
                    type="center"
                >
                    <HeaderContent>
                        <HeaderBrand iconName={logo}>
                            <h2>
                                Profilazione
                            </h2>
                            <h3>
                                Regione Sicilia
                            </h3>
                        </HeaderBrand>
                        <HeaderRightZone>
                            {keycloak.authenticated && <UserAvatar />}
                        </HeaderRightZone>
                    </HeaderContent>
                </Header>
                <Header theme="" type="navbar">
                    <HeaderContent
                        expand="lg"
                        megamenu
                    >
                        <HeaderToggler
                            aria-controls="nav1"
                            aria-label="Toggle navigation"
                            onClick={toggle}
                            aria-expanded={collapse}
                        >
                            <Icon icon="it-burger" />
                        </HeaderToggler>
                        <Collapse
                            header
                            navbar
                            onOverlayClick={toggle}
                            isOpen={collapse}
                        >
                            <div className="menu-wrapper">
                                <Nav navbar>
                                    <NavItem>
                                        <NavLink
                                            as={Link} to="/profili"
                                            active={location.pathname === "/profili"}
                                            onClick={() => {
                                                if (location.pathname === "/utenti" && isPageChanged) {
                                                    setNavigateTo("/profili");
                                                    toggleModalTabChange(!isRequestedATabChange);
                                                } else {
                                                    navigate("/profili")
                                                    setIsPageChanged(false);
                                                }

                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <span>
                                                Profili{' '}
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            as={Link} to="/utenti"
                                            active={location.pathname === "/utenti"}
                                            onClick={() => {
                                                if (location.pathname === "/profili" && isPageChanged) {
                                                    setNavigateTo("/utenti");
                                                    toggleModalTabChange(!isRequestedATabChange);
                                                } else {
                                                    navigate("/utenti")
                                                    setIsPageChanged(false);
                                                }
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <span>
                                                Utenti{' '}
                                            </span >
                                        </NavLink >
                                    </NavItem >
                                </Nav>
                            </div>
                        </Collapse>
                    </HeaderContent>
                </Header>
                <Modal
                    isOpen={isRequestedATabChange}
                    toggle={() => toggleModalTabChange(!isRequestedATabChange)}
                >
                    <ModalHeader toggle={() => toggleModalTabChange(!isRequestedATabChange)} id='esempio12'>
                        Indietro
                    </ModalHeader>
                    <ModalBody>
                        <p>Tutte le modifiche andranno perse. Sei sicuro di voler continuare?</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="buttonPrimary" onClick={() => toggleModalTabChange(!isRequestedATabChange)}>
                            Annulla
                        </Button>
                        <Button className="buttonPrimary" onClick={() => { navigate(navigateTo); toggleModalTabChange(!isRequestedATabChange); setIsPageChanged(false); }}
                        >
                            Conferma
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>

        </Headers>
    );
}

export default CustomHeader;