import CustomHeader from "./components/common/CustomHeader";
import CustomFooter from "./components/common/CustomFooter";
import RootPage from "./components/RootPage";
import { BrowserRouter as Router } from "react-router-dom";
import packageInfo from "../package.json";
import Loading from './components/common/Loading';
import React, { useState } from "react";
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';



function App() {
  const [isPageChanged, setIsPageChanged] = useState(false);
  return (
    <ReactKeycloakProvider authClient={keycloak} onReady={() => { keycloak.updateToken(5) }} 
      initOptions={{ onLoad: 'login-required', pkceMethod: 'S256', checkLoginIframe: false }} >
      <Router basename={packageInfo.homepage}>
        <Loading/>
        <CustomHeader isPageChanged={isPageChanged} setIsPageChanged={setIsPageChanged}/>
        <RootPage isPageChanged={isPageChanged} setIsPageChanged={setIsPageChanged}/>
        <CustomFooter />
      </Router>
    </ReactKeycloakProvider>
  );
}

export default App;