import axios from 'axios';

const BASE_ENDPOINT = window._env_.BASE_ENDPOINT;

const searchUtenti = payload => {
    return axios.post(`${BASE_ENDPOINT}/v1/utenti/search`, payload);
};

const deleteUtente = payload => {
    return axios.delete(`${BASE_ENDPOINT}/v1/utenti/` + payload);
};

const saveUtente = payload => {
    return axios.put(`${BASE_ENDPOINT}/v1/utenti`, payload);
};

const getUtente = payload => {
    return axios.get(`${BASE_ENDPOINT}/v1/utenti/` + payload);
};

const associaUtenti = payload => {
    return axios.post(`${BASE_ENDPOINT}/v1/utenti/associa`, payload);
};

const deleteAssociaUtenti = payload => {
    return axios.post(`${BASE_ENDPOINT}/v1/utenti/deleteassociaprofilo`, payload);
};

const deleteAssocia = payload => {
    return axios.post(`${BASE_ENDPOINT}/v1/utenti/deleteassocia`, payload);
};

export default {
    deleteAssocia,
    associaUtenti,
    deleteAssociaUtenti,
    saveUtente,
    deleteUtente,
    searchUtenti,
    getUtente
};