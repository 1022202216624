import { Input, LinkListItem, UncontrolledTooltip, LinkList, Col, Select, Row, Button, Table, CardTitle, Alert, Icon, ModalFooter, ModalBody, ModalHeader, Modal, Tooltip } from 'design-react-kit';
import React, { useState, useEffect } from 'react';
import profiliService from '../service/profiliService';
import { wait } from '../common/Loading';
import '../../App.css';
import { useNavigate, useLocation } from "react-router-dom";
import { PaginationControl } from '../custom/PaginationControl';
import { CustomBreadCrumb } from '../custom/CustomBreadCrumb';

function ProfiloPage({ isPageChanged, setIsPageChanged }) {
    const navigate = useNavigate();
    const [codProfilo, setCodProfilo] = useState("");
    const [nomeProfilo, setNomeProfilo] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [sortOrder, setOrder] = useState("asc");
    const [sortKey, setSortKey] = useState("nomeProfilo");
    const [profiloList, setProfiloList] = useState([]);
    const [isOpenDelete, toggleModalDelete] = useState(false);

    const [item, setItem] = useState(null);

    const handleDeleteToggle = (item) => {
        setItem(item);
        toggleModalDelete(!isOpenDelete);
    }

    const confirmDeleteClick = (item) => {
        handleDelete(item);
        toggleModalDelete(!isOpenDelete);
    }

    const [alert, setAlert] = useState({
        'state': false,
        'text': '',
        'type': ''
    });
    const [cercaTooltip, setCercaTooltip] = useState(false);
    const [inserisciTooltip, setInserisciTooltip] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        handleSubmit();
    }, []);

    useEffect(() => {
        handleSubmit();
    }, [pageNumber]);


    const handleSubmit = () => {
        setAlert({
            'state': false,
            'text': "",
            'type': ''
        });
        let count = 0;

        //CONTROLLI FILTER

        if (count <= 0) {
            let payload = {
                data: {
                    nomeProfilo: nomeProfilo,
                    codProfilo: codProfilo,
                },
                paginationData: {
                    pageSize: pageSize,
                    pageNumber: pageNumber,
                    sortKey: sortKey,
                    sortOrder: sortOrder
                }
            };
            wait(profiliService.searchProfili(payload))
                .then((response) => {
                    setNumberOfPages(Math.ceil(response.data.paginationData.numElements / pageSize));
                    let profiliTmp = [];
                    if (response && response.data && response.data.data) {
                        response.data.data.map((index) => {
                            profiliTmp.push({
                                'nomeProfilo': index.nomeProfilo,
                                'codProfilo': index.codProfilo,
                                'idProfilo': index.idProfilo,
                            });
                        });
                        setProfiloList(profiliTmp)
                    } else
                        setProfiloList([]);
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setAlert({
                            'state': true,
                            'text': error.response.data.apierror.debugMessage,
                            'type': 'danger'
                        });
                        return;
                    } else {
                        setAlert({
                            'state': true,
                            'text': "Errore durante l'operazione",
                            'type': 'danger'
                        });
                        return;
                    }
                });
        }

    }

    const customStylesInputSelect = {
        menuPortal: base => ({
            ...base,
            zIndex: 999
        })
    };

    const handleNavigation = (pageStatus, item) => {
        navigate("/gestione-profili", {
            state: {
                pageStatus: pageStatus,
                id: pageStatus && pageStatus === "insert" ? null : item && item.idProfilo
            }
        });
    };

    const handleDelete = (item) => {
        wait(profiliService.deleteProfilo(item.idProfilo))
            .then((response) => {
                handleSubmit();
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                    setAlert({
                        'state': true,
                        'text': error.response.data.apierror.debugMessage,
                        'type': 'danger'
                    });
                    return;
                } else {
                    setAlert({
                        'state': true,
                        'text': "Errore durante l'operazione",
                        'type': 'danger'
                    });
                    return;
                }
            });
    };

    return (<>
        <Col>
            <CustomBreadCrumb
                first={{
                    "name": "Elenco Profili",
                    "onClick": () => { },
                    "active": true
                }}
            />
            <span class='bi bi-check-circle' />
            <Row style={{ marginTop: '4vh' }}>
                <Col xs={12} md={3}  >
                    <Input
                        type='text'
                        className='clickableLabel'
                        label='Nome Profilo'
                        value={nomeProfilo}
                        onChange={(event) => { setNomeProfilo(event.target.value); }}
                    />
                </Col>
                <Col xs={12} md={3}  >
                    <Input
                        type='text'
                        className='clickableLabel'
                        label='Codice Profilo'
                        value={codProfilo}
                        onChange={(event) => { setCodProfilo(event.target.value); }}
                    />
                </Col>
            </Row>
            <Row className='d-flex justify-content-end'>
                <Col xs={12} xl={2}>
                    <Button className="buttonPrimary"
                        id='inserisci_btn'
                        color='primary'
                        block
                        onClick={() => {
                            handleNavigation("insert", null);
                        }}
                    >
                        INSERISCI
                    </Button>{' '}
                </Col>
                <Tooltip
                    placement='top'
                    isOpen={inserisciTooltip}
                    target={'inserisci_btn'}
                    toggle={() => setInserisciTooltip(!inserisciTooltip)}
                >
                    Inserisci Profilo
                </Tooltip>
                <Col xs={12} xl={2}>
                    <Button className="buttonPrimary"
                        id='cerca_btn'
                        color='primary'
                        block
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        CERCA
                    </Button>{' '}
                </Col>
                <Tooltip
                    placement='top'
                    isOpen={cercaTooltip}
                    target={'cerca_btn'}
                    toggle={() => setCercaTooltip(!cercaTooltip)}
                >
                    Cerca Profili
                </Tooltip>
            </Row>
            <LinkList>
                <LinkListItem divider />
            </LinkList>
            {
                profiloList && profiloList.length !== 0 ? (<Row className='d-flex justify-content-end'>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th scope='col'>Nome Profilo</th>
                                <th scope='col'>Codice Profilo</th>
                                <th scope='col' class="text-center">Azioni</th>
                            </tr>
                        </thead>
                        <tbody>
                            {profiloList.map((item, index) => (
                                <tr>
                                    <td>{item.nomeProfilo}</td>
                                    <td>{item.codProfilo}</td>
                                    <td class="text-center">
                                        <Button title="Visualizza" onClick={() => {
                                            handleNavigation("detail", item);
                                        }}> <Icon icon={"it-password-visible"} /></Button>
                                        <Button title="Aggiorna" onClick={() => {
                                            handleNavigation("update", item);
                                        }}>  <Icon icon={"it-pencil"} /></Button>
                                        <Button title="Elimina" onClick={() => {
                                            handleDeleteToggle(item);
                                        }}>  <Icon icon={"it-delete"} /></Button>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </Table></Row>) : (<Row className='d-flex justify-content-center'><p>Nessun risultato trovato</p></Row>)
            }

            {isOpenDelete &&
                <Modal
                    isOpen={isOpenDelete}
                    toggle={() => toggleModalDelete(!isOpenDelete)}
                >
                    <ModalHeader toggle={() => toggleModalDelete(!isOpenDelete)} id='esempio12'>
                        Conferma
                    </ModalHeader>
                    <ModalBody>
                        <p>L'operazione è irreversibile. Sei sicuro di voler procedere?</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="buttonPrimary" onClick={() => toggleModalDelete(!isOpenDelete)}>
                            Annulla
                        </Button>
                        <Button className="buttonPrimary" onClick={() => confirmDeleteClick(item)}>
                            Conferma
                        </Button>
                    </ModalFooter>
                </Modal>}

            {alert.state &&
                <div>
                    <div className='modal-content'>
                        <Modal fade centered isOpen={alert.state}>
                            <ModalHeader>Errore</ModalHeader>
                            <ModalBody>{alert.text}</ModalBody>
                            <ModalFooter>
                                <Button className="buttonPrimary" onClick={() => {
                                    setAlert({
                                        'state': false,
                                        'text': "",
                                        'type': ''
                                    });
                                }} color='secondary'>Chiudi</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </div>
            }
            <Row style={{ display: "flex", justifyContent: "end" }}>
                <PaginationControl
                    selectedPage={pageNumber}
                    numberOfPages={numberOfPages}
                    changePage={setPageNumber}
                />
            </Row>
        </Col></>
    );
}

export default ProfiloPage;