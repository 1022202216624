import axios from 'axios';

const BASE_ENDPOINT = window._env_.BASE_ENDPOINT;

const searchProfili = payload => {
    return axios.post(`${BASE_ENDPOINT}/v1/profili/search`, payload);
};

const deleteProfilo = payload => {
    return axios.delete(`${BASE_ENDPOINT}/v1/profili/` + payload);
};

const saveProfilo = payload => {
    return axios.put(`${BASE_ENDPOINT}/v1/profili`, payload);
};

const getProfilo = payload => {
    return axios.get(`${BASE_ENDPOINT}/v1/profili/` + payload);
};

export default {
    saveProfilo,
    deleteProfilo,
    searchProfili,
    getProfilo
};