import {
    Toggle, Select, Tooltip, Icon, Table, Accordion, Alert, AccordionBody, AccordionHeader, Button, Col, Container, Input, LinkList, LinkListItem, Modal, ModalBody, ModalFooter, ModalHeader, Row
} from 'design-react-kit';
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import '../../App.css';
import { isEmpty } from '../common/stringUtils';
import { CustomBreadCrumb } from '../custom/CustomBreadCrumb';
import utentiService from '../service/utentiService';
import tipologiaService from '../service/tipologiaService';
import { wait } from '../common/Loading';

function GestioneUtente({ isPageChanged, setIsPageChanged }) {

    const [alert, setAlert] = useState({
        'state': false,
        'text': '',
        'type': ''
    });
    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        wait(tipologiaService.getProfili({}))
            .then((response) => {
                let profiloTmp = [];
                response && response.data && response.data.data.map((index) => {
                    profiloTmp.push({
                        'label': index.descrizione,
                        'value': index.id
                    });
                });
                setProfiloList(profiloTmp);
            });
    }, []);

    const customStylesInputSelect = {
        menuPortal: base => ({
            ...base,
            zIndex: 999
        })
    };

    const [aziendaProfiloList, setAziendaProfiloList] = useState([]);
    const [presidioProfiloList, setPresidioProfiloList] = useState([]);
    const [strutturaProfiloList, setStrutturaProfiloList] = useState([]);
    const [strutturaProfiloPresidioList, setStrutturaProfiloPresidioList] = useState([]);

    const [profiloAss, setProfiloAss] = useState(null);

    const toggleAzienda = () => { toggleModalAzienda(!openAzienda) };
    const [openAzienda, toggleModalAzienda] = useState(false);

    const togglePresidio = () => { toggleModalPresidio(!openPresidio) };
    const [openPresidio, toggleModalPresidio] = useState(false);

    const toggleStruttura = () => { toggleModalStruttura(!openStruttura) };
    const [openStruttura, toggleModalStruttura] = useState(false);

    //PROFILO
    const [profilo, setProfilo] = useState(null);
    const [profiloList, setProfiloList] = useState([]);
    const [isProfiloEnabled, setProfiloEnabled] = useState(false);
    const [utenteProfiloList, setUtenteProfiloList] = useState([]);

    //AZIENDA
    const [isAzienda1Enabled, setAzienda1Enabled] = useState(false);
    const [azienda1, setAzienda1] = useState(null);
    const [aziendaCombo1, setAziendaCombo1] = useState([]);

    const [profiloAziende, setProfiloAziende] = useState(null);
    const [isProfiloAziendeEnabled, setProfiloAziendeEnabled] = useState(false);

    const [azienda, setAzienda] = useState(null);
    const [isAziendaEnabled, setAziendaEnabled] = useState(false);

    //PRESIDIO
    const [isAzienda2Enabled, setAzienda2Enabled] = useState(false);
    const [azienda2, setAzienda2] = useState(null);
    const [aziendaCombo2, setAziendaCombo2] = useState([]);

    const [isPresidio2Enabled, setPresidio2Enabled] = useState(false);
    const [presidio2, setPresidio2] = useState(null);
    const [presidioCombo2, setPresidioCombo2] = useState([]);

    const [profiloPresidi, setProfiloPresidi] = useState(null);
    const [isProfiloPresidiEnabled, setProfiloPresidiEnabled] = useState(false);
    const [presidio, setPresidio] = useState(null);
    const [isPresidioEnabled, setPresidioEnabled] = useState(false);

    //STRUTTURA AZIENDA

    const [isAzienda3Enabled, setAzienda3Enabled] = useState(false);
    const [azienda3, setAzienda3] = useState(null);
    const [aziendaCombo3, setAziendaCombo3] = useState([]);


    const [isPresidio3Enabled, setPresidio3Enabled] = useState(false);
    const [presidio3, setPresidio3] = useState(null);
    const [presidioCombo3, setPresidioCombo3] = useState([]);


    const [isStruttura3Enabled, setStruttura3Enabled] = useState(false);
    const [struttura3, setStruttura3] = useState(null);
    const [strutturaCombo3, setStrutturaCombo3] = useState([]);

    const [profiloStrutture, setProfiloStrutture] = useState(null);
    const [isProfiloStruttureEnabled, setProfiloStruttureEnabled] = useState(false);

    //STRUTTURA AZIENDA

    const [isAzienda4Enabled, setAzienda4Enabled] = useState(false);
    const [azienda4, setAzienda4] = useState(null);
    const [aziendaCombo4, setAziendaCombo4] = useState([]);


    const [isPresidio4Enabled, setPresidio4Enabled] = useState(false);
    const [presidio4, setPresidio4] = useState(null);
    const [presidioCombo4, setPresidioCombo4] = useState([]);


    const [isStruttura4Enabled, setStruttura4Enabled] = useState(false);
    const [struttura4, setStruttura4] = useState(null);
    const [strutturaCombo4, setStrutturaCombo4] = useState([]);

    const [profiloStrutturePresidio, setProfiloStrutturePresidio] = useState(null);
    const [isProfiloStrutturePresidioEnabled, setProfiloStrutturePresidioEnabled] = useState(false);

    const [struttura, setStruttura] = useState(null);
    const [isStrutturaEnabled, setStrutturaEnabled] = useState(false);


    const [isSalvaClicked, setIsSalvaClicked] = useState(false);

    const [idUtente, setIdUtente] = useState(location && location.state && location.state.id ? location.state.id : null);
    const [codFiscale, setCodFiscale] = useState("");
    const [codFiscaleHelper, setCodFiscaleHelper] = useState("");
    const [codFiscaleError, setCodFiscaleError] = useState(false);

    const [isOpenSalva, toggleModalSalva] = useState(false);
    const [isOpenIndietro, toggleModalIndietro] = useState(false);

    const [dettaglioPage, setDettaglioPage] = useState(false);

    const [collapseOpen1, setCollapseOpen1] = useState(true);
    const [collapseOpen2, setCollapseOpen2] = useState(true);
    const [collapseOpen3, setCollapseOpen3] = useState(true);
    const [collapseOpen4, setCollapseOpen4] = useState(true);
    const [collapseOpen5, setCollapseOpen5] = useState(true);
    const [collapseOpen6, setCollapseOpen6] = useState(true);

    const [itemToNavigateProfili, setItemToNavigateProfili] = useState(null);
    const [causeForNavigateProfili, setCauseForNavigateProfili] = useState(null);

    const [isOpenProfiliChange, toggleModalProfili] = useState(false);

    const [inserisciTooltip, setInserisciTooltip] = useState(false);

    const [itemProfilo, setItemProfilo] = useState(null);

    const [profiliList, setProfiliList] = useState([]);

    const [profiliCombo, setProfiliCombo] = useState([]);

    const [isOpenDeleteProfilo, toggleModalDeleteProfilo] = useState(false);

    const handleNavigation = (pageStatus, item) => {
        navigate("/gestione-presidi", {
            state: {
                pageStatus: pageStatus,
                id: pageStatus && pageStatus === "insert" ? null : item && item.idProfilo,
            }
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (location && location.state && (location.state.pageStatus === "update" || location.state.pageStatus === "detail")) {
            if (location.state.pageStatus === "detail") { setDettaglioPage(true); }
            wait(utentiService.getUtente(location.state.id))
                .then((response) => {
                    if (response && response.data) {
                        buildUtente(response.data);
                        setAlert({
                            'state': false,
                            'text': "",
                            'type': ''
                        });
                    }
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setAlert({
                            'state': true,
                            'text': error.response.data.apierror.debugMessage,
                            'type': 'danger'
                        });
                        return;
                    } else {
                        setAlert({
                            'state': true,
                            'text': "Errore durante l'operazione",
                            'type': 'danger'
                        });
                        return;
                    }
                });
        }
    }, []);


    const buildUtente = (data) => {
        setCodFiscale(data.fiscalCode);
        setCodFiscaleError(data.fiscalCode === null);
        setCodFiscaleHelper("Campo obbligatorio");
        setIdUtente(data.idUtente);
        setProfiliList(data.profili);
        let profiloTmp = [];
        data.profili.map((index) => {
            profiloTmp.push({
                'label': index.descrizione,
                'value': index.id
            });
        });
        setProfiliCombo(profiloTmp);
        setUtenteProfiloList(data.userProfileData);
        let aziendeTmp = [];
        data.userProfileData.map((index) => {
            if (index.azienda) {
                aziendeTmp.push({
                    'descrizione': index.azienda.descrizione,
                    'codice': index.azienda.codice,
                    'profilo': index.profilo.descrizione,
                    'id': index.idUtenteProflo,
                });
            }
        });
        setAziendaProfiloList(aziendeTmp);
        let presidiTmp = [];
        data.userProfileData.map((index) => {
            if (index.presidio) {
                presidiTmp.push({
                    'descrizione': index.presidio.descrizione,
                    'codice': index.presidio.codice,
                    'profilo': index.profilo.descrizione,
                    'id': index.idUtenteProflo,
                });
            }
        });
        setPresidioProfiloList(presidiTmp);
        let struttureTmp = [];
        data.userProfileData.map((index) => {
            if (index.struttura && index.struttura.desAzienda) {
                struttureTmp.push({
                    'descrizione': index.struttura.descrizione,
                    'codice': index.struttura.codice,
                    'profilo': index.profilo.descrizione,
                    'desAzienda': index.struttura.desAzienda,
                    'id': index.idUtenteProflo,
                });
            }
        });
        setStrutturaProfiloList(struttureTmp);
        let strutturePresidioTmp = [];
        data.userProfileData.map((index) => {
            if (index.struttura && index.struttura.desPresidio) {
                strutturePresidioTmp.push({
                    'descrizione': index.struttura.descrizione,
                    'codice': index.struttura.codice,
                    'profilo': index.profilo.descrizione,
                    'desPresidio': index.struttura.desPresidio,
                    'id': index.idUtenteProflo,
                });
            }
        });
        setStrutturaProfiloPresidioList(strutturePresidioTmp);
    }

    const handleSubmit = () => {
        let error = 0;
        setAlert({
            'state': false,
            'text': "",
            'type': ''
        });
        if (isEmpty(codFiscale)) {
            setCodFiscaleHelper("Campo obbligatorio");
            setCodFiscaleError(true);
            error++;
        } else if (codFiscale.length !== 16) {
            setCodFiscaleHelper("La lunghezza del campo deve essere di 16 caratteri");
            setCodFiscaleError(true);
            error++;
        } else {
            setCodFiscaleHelper("");
            setCodFiscaleError(false)
        }

        if (error < 1) {
            let payload = {
                idUtente: idUtente,
                fiscalCode: codFiscale,
            };
            wait(utentiService.saveUtente(payload))
                .then((result) => {
                    navigate("/utenti");
                    setIsPageChanged(false);
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setAlert({
                            'state': true,
                            'text': error.response.data.apierror.debugMessage,
                            'type': 'danger'
                        });
                        return;
                    } else {
                        setAlert({
                            'state': true,
                            'text': "Errore durante l'operazione",
                            'type': 'danger'
                        });
                        return;
                    }
                });
        } else {
            setIsSalvaClicked(true);
        }
    }

    const confirmSalvaClick = () => {
        handleSubmit();
        toggleModalSalva(false);
    }


    const handleAssociaProfilo = () => {
        setAlert({
            'state': false,
            'text': "",
            'type': ''
        });
        let payload = {
            idUtente: idUtente,
            idProfile: profilo,
        };
        wait(utentiService.associaUtenti(payload))
            .then((result) => {
                wait(tipologiaService.getProfili({}))
                    .then((response) => {
                        let profiloTmp = [];
                        response.data.data.map((index) => {
                            profiloTmp.push({
                                'label': index.descrizione,
                                'value': index.id
                            });
                        });
                        setProfiloList(profiloTmp);
                    });
                wait(utentiService.getUtente(location.state.id))
                    .then((response) => {
                        if (response && response.data) {
                            buildUtente(response.data);
                            setAlert({
                                'state': false,
                                'text': "",
                                'type': 'danger'
                            });
                        }
                    }).catch((error) => {
                        if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                            setAlert({
                                'state': true,
                                'text': error.response.data.apierror.debugMessage,
                                'type': 'danger'
                            }); return;
                        } else {
                            setAlert({
                                'state': true,
                                'text': "Errore durante l'operazione",
                                'type': 'danger'
                            });
                            return;
                        }
                    });
                // navigate("/utenti");
                // setIsPageChanged(false);
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                    setAlert({
                        'state': true,
                        'text': error.response.data.apierror.debugMessage,
                        'type': 'danger'
                    });
                    return;
                } else {
                    setAlert({
                        'state': true,
                        'text': "Errore durante l'operazione",
                        'type': 'danger'
                    });
                    return;
                }
            });
    }

    const handleAssociaAmbulatorio = () => {
        setAlert({
            'state': false,
            'text': "",
            'type': ''
        });
        let payload = {
            idUtente: idUtente,
            idProfile: profiloStrutture,
            idStruttura: struttura3,
        };
        wait(utentiService.associaUtenti(payload))
            .then((result) => {
                wait(tipologiaService.getProfili({}))
                    .then((response) => {
                        let profiloTmp = [];
                        response.data.data.map((index) => {
                            profiloTmp.push({
                                'label': index.descrizione,
                                'value': index.id
                            });
                        });
                        setProfiloList(profiloTmp);
                    });
                wait(utentiService.getUtente(location.state.id))
                    .then((response) => {
                        if (response && response.data) {
                            buildUtente(response.data);
                            setAlert({
                                'state': false,
                                'text': "",
                                'type': ''
                            });
                            setProfiloStrutture(null);
                        }
                    }).catch((error) => {
                        if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                            setAlert({
                                'state': true,
                                'text': error.response.data.apierror.debugMessage,
                                'type': 'danger'
                            });
                            return;
                        } else {
                            setAlert({
                                'state': true,
                                'text': "Errore durante l'operazione",
                                'type': 'danger'
                            });
                            return;
                        }
                    });
                // navigate("/utenti");
                // setIsPageChanged(false);
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                    setAlert({
                        'state': true,
                        'text': error.response.data.apierror.debugMessage,
                        'type': 'danger'
                    });
                    return;
                } else {
                    setAlert({
                        'state': true,
                        'text': "Errore durante l'operazione",
                        'type': 'danger'
                    });
                    return;
                }
            });
    }

    const handleAssociaReparto = () => {
        setAlert({
            'state': false,
            'text': "",
            'type': ''
        });
        let payload = {
            idUtente: idUtente,
            idProfile: profiloStrutturePresidio,
            idStruttura: struttura4
        };
        wait(utentiService.associaUtenti(payload))
            .then((result) => {
                wait(tipologiaService.getProfili({}))
                    .then((response) => {
                        let profiloTmp = [];
                        response.data.data.map((index) => {
                            profiloTmp.push({
                                'label': index.descrizione,
                                'value': index.id
                            });
                        });
                        setProfiloList(profiloTmp);
                    });
                wait(utentiService.getUtente(location.state.id))
                    .then((response) => {
                        if (response && response.data) {
                            buildUtente(response.data);
                            setAlert({
                                'state': false,
                                'text': "",
                                'type': ''
                            });
                            setProfiloStrutture(null);
                        }
                    }).catch((error) => {
                        if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                            setAlert({
                                'state': true,
                                'text': error.response.data.apierror.debugMessage,
                                'type': 'danger'
                            });
                            return;
                        } else {
                            setAlert({
                                'state': true,
                                'text': "Errore durante l'operazione",
                                'type': 'danger'
                            });
                            return;
                        }
                    });
                // navigate("/utenti");
                // setIsPageChanged(false);
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                    setAlert({
                        'state': true,
                        'text': error.response.data.apierror.debugMessage,
                        'type': 'danger'
                    });
                    return;
                } else {
                    setAlert({
                        'state': true,
                        'text': "Errore durante l'operazione",
                        'type': 'danger'
                    });
                    return;
                }
            });
    }

    const handleAssociaPresidio = () => {
        setAlert({
            'state': false,
            'text': "",
            'type': ''
        });
        let payload = {
            idUtente: idUtente,
            idProfile: profiloPresidi,
            idPresidio: presidio2
        };
        wait(utentiService.associaUtenti(payload))
            .then((result) => {
                wait(tipologiaService.getProfili({}))
                    .then((response) => {
                        let profiloTmp = [];
                        response.data.data.map((index) => {
                            profiloTmp.push({
                                'label': index.descrizione,
                                'value': index.id
                            });
                        });
                        setProfiloList(profiloTmp);
                    });
                wait(utentiService.getUtente(location.state.id))
                    .then((response) => {
                        if (response && response.data) {
                            buildUtente(response.data);
                            setAlert({
                                'state': false,
                                'text': "",
                                'type': ''
                            });
                        }
                    }).catch((error) => {
                        if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                            setAlert({
                                'state': true,
                                'text': error.response.data.apierror.debugMessage,
                                'type': 'danger'
                            });
                            return;
                        } else {
                            setAlert({
                                'state': true,
                                'text': "Errore durante l'operazione",
                                'type': 'danger'
                            });
                            return;
                        }
                    });

                setProfiloPresidi(null);
                // navigate("/utenti");
                // setIsPageChanged(false);
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                    setAlert({
                        'state': true,
                        'text': error.response.data.apierror.debugMessage,
                        'type': 'danger'
                    });
                    return;
                } else {
                    setAlert({
                        'state': true,
                        'text': "Errore durante l'operazione",
                        'type': 'danger'
                    });
                    return;
                }
            });
    }

    const handleAssociaAzienda = () => {
        setAlert({
            'state': false,
            'text': "",
            'type': ''
        });
        let payload = {
            idUtente: idUtente,
            idProfile: profiloAziende,
            idAzienda: azienda1
        };
        wait(utentiService.associaUtenti(payload))
            .then((result) => {
                wait(tipologiaService.getProfili({}))
                    .then((response) => {
                        let profiloTmp = [];
                        response.data.data.map((index) => {
                            profiloTmp.push({
                                'label': index.descrizione,
                                'value': index.id
                            });
                        });
                        setProfiloList(profiloTmp);
                    });
                wait(utentiService.getUtente(location.state.id))
                    .then((response) => {
                        if (response && response.data) {
                            buildUtente(response.data);
                            setAlert({
                                'state': false,
                                'text': "",
                                'type': ''
                            });
                        }
                    }).catch((error) => {
                        if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                            setAlert({
                                'state': true,
                                'text': error.response.data.apierror.debugMessage,
                                'type': 'danger'
                            });
                            return;
                        } else {
                            setAlert({
                                'state': true,
                                'text': "Errore durante l'operazione",
                                'type': 'danger'
                            });
                            return;
                        }
                    });

                setProfiloAziende(null);
                // navigate("/utenti");
                // setIsPageChanged(false);
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                    setAlert({
                        'state': true,
                        'text': error.response.data.apierror.debugMessage,
                        'type': 'danger'
                    });
                    return;
                } else {
                    setAlert({
                        'state': true,
                        'text': "Errore durante l'operazione",
                        'type': 'danger'
                    });
                    return;
                }
            });
    }

    const onChangeProfilo = (event) => {
        setProfilo(event);
        setProfiloEnabled(false);
    }

    const onChangeAzienda2 = (event) => {
        setAzienda2(event);
        setAzienda2Enabled(false);
        if (event) {
            let payload = {
                data: {
                    idAzienda: event,
                }
            };
            wait(tipologiaService.getPresidi(payload))
                .then((response) => {
                    let presidiTmp = [];
                    response.data.data.map((index) => {
                        presidiTmp.push({
                            'label': index.descrizione,
                            'value': index.id
                        });
                    });
                    setPresidioCombo2(presidiTmp);
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setAlert({
                            'state': true,
                            'text': error.response.data.apierror.debugMessage,
                            'type': 'danger'
                        });
                        return;
                    } else {
                        setAlert({
                            'state': true,
                            'text': "Errore durante l'operazione",
                            'type': 'danger'
                        });
                        return;
                    }
                });
        } else {
            setPresidio2(null);
            setPresidioCombo2([]);
        }
    }



    const onChangeAzienda4 = (event) => {
        setAzienda4(event);
        setAzienda4Enabled(false);
        if (event) {
            let payload = {
                data: {
                    idAzienda: event,
                }
            };
            wait(tipologiaService.getPresidi(payload))
                .then((response) => {
                    let presidiTmp = [];
                    response.data.data.map((index) => {
                        presidiTmp.push({
                            'label': index.descrizione,
                            'value': index.id
                        });
                    });
                    setPresidioCombo4(presidiTmp);
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setAlert({
                            'state': true,
                            'text': error.response.data.apierror.debugMessage,
                            'type': 'danger'
                        });
                        return;
                    } else {
                        setAlert({
                            'state': true,
                            'text': "Errore durante l'operazione",
                            'type': 'danger'
                        });
                        return;
                    }
                });
        } else {
            setPresidio4(null);
            setStruttura4(null);
            setPresidioCombo4([]);
            setStrutturaCombo4([]);
        }
    }

    const onChangeAzienda3 = (event) => {
        setAzienda3(event);
        setAzienda3Enabled(false);
        if (event) {
            let payload = {
                data: {
                    idAzienda: event,
                }
            };
            wait(tipologiaService.getStrutture(payload))
                .then((response) => {
                    let strutturaTmp = [];
                    response.data.data.map((index) => {
                        strutturaTmp.push({
                            'label': index.descrizione,
                            'value': index.id
                        });
                    });
                    setStrutturaCombo3(strutturaTmp);
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setAlert({
                            'state': true,
                            'text': error.response.data.apierror.debugMessage,
                            'type': 'danger'
                        });
                        return;
                    } else {
                        setAlert({
                            'state': true,
                            'text': "Errore durante l'operazione",
                            'type': 'danger'
                        });
                        return;
                    }
                });
            wait(tipologiaService.getPresidi(payload))
                .then((response) => {
                    let presidiTmp = [];
                    response.data.data.map((index) => {
                        presidiTmp.push({
                            'label': index.descrizione,
                            'value': index.id
                        });
                    });
                    setPresidioCombo3(presidiTmp);
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setAlert({
                            'state': true,
                            'text': error.response.data.apierror.debugMessage,
                            'type': 'danger'
                        });
                        return;
                    } else {
                        setAlert({
                            'state': true,
                            'text': "Errore durante l'operazione",
                            'type': 'danger'
                        });
                        return;
                    }
                });
        } else {
            setPresidio3(null);
            setStruttura3(null);
            setPresidioCombo3([]);
            setStrutturaCombo3([]);
        }
    }


    const onChangeAzienda1 = (event) => {
        setAzienda1(event);
        setAzienda1Enabled(false);
    }

    const onChangePresidio2 = (event) => {
        setPresidio2(event);
        setPresidio2Enabled(false);
    }

    const onChangePresidio3 = (event) => {
        setPresidio3(event);
        setPresidio3Enabled(false);
        if (event) {
            let payload = {
                data: {
                    idStruttura: event,
                }
            };
            wait(tipologiaService.getStrutture(payload))
                .then((response) => {
                    let strutturaTmp = [];
                    response.data.data.map((index) => {
                        strutturaTmp.push({
                            'label': index.descrizione,
                            'value': index.id
                        });
                    });
                    setStrutturaCombo3(strutturaTmp);
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setAlert({
                            'state': true,
                            'text': error.response.data.apierror.debugMessage,
                            'type': 'danger'
                        });
                        return;
                    } else {
                        setAlert({
                            'state': true,
                            'text': "Errore durante l'operazione",
                            'type': 'danger'
                        });
                        return;
                    }
                });
        } else {
            if (!azienda3) {
                setStruttura3(null);
                setStrutturaCombo3([]);
            }
        }
    }

    const onChangePresidio4 = (event) => {
        setPresidio4(event);
        setPresidio4Enabled(false);
        if (event) {
            let payload = {
                data: {
                    idPresidio: event,
                }
            };
            wait(tipologiaService.getStrutture(payload))
                .then((response) => {
                    let strutturaTmp = [];
                    response.data.data.map((index) => {
                        strutturaTmp.push({
                            'label': index.descrizione,
                            'value': index.id
                        });
                    });
                    setStrutturaCombo4(strutturaTmp);
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setAlert({
                            'state': true,
                            'text': error.response.data.apierror.debugMessage,
                            'type': 'danger'
                        });
                        return;
                    } else {
                        setAlert({
                            'state': true,
                            'text': "Errore durante l'operazione",
                            'type': 'danger'
                        });
                        return;
                    }
                });
        } else {
            if (!azienda4) {
                setStruttura4(null);
                setStrutturaCombo4([]);
            }
        }
    }

    const onChangeStruttura3 = (event) => {
        setStruttura3(event);
        setStruttura3Enabled(false);
    }


    const onChangeStruttura4 = (event) => {
        setStruttura4(event);
        setStruttura4Enabled(false);
    }

    const onChangeProfiloAziende = (event) => {
        setProfiloAziende(event);
        let aziendeTmp = [];
        utenteProfiloList.map((index) => {
            if ((index.profilo.id === event || event == null) && index.azienda) {
                aziendeTmp.push({
                    'descrizione': index.azienda.descrizione,
                    'codice': index.azienda.codice,
                    'id': index.idUtenteProflo,
                });
            }
        });
        setAziendaProfiloList(aziendeTmp);
        setProfiloAziendeEnabled(false);
        if (event) {
            let payload = {
            };
            wait(tipologiaService.getAziende(payload))
                .then((response) => {
                    let aziendeTmp = [];
                    response.data.data.map((index) => {
                        aziendeTmp.push({
                            'label': index.descrizione,
                            'value': index.id
                        });
                    });
                    setAziendaCombo1(aziendeTmp);
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setAlert({
                            'state': true,
                            'text': error.response.data.apierror.debugMessage,
                            'type': 'danger'
                        });
                        return;
                    } else {
                        setAlert({
                            'state': true,
                            'text': "Errore durante l'operazione",
                            'type': 'danger'
                        });
                        return;
                    }
                });
        } else {
            setAzienda1(null);
            setAziendaCombo1([]);
        }
    }

    const onChangeProfiloPresidi = (event) => {
        setProfiloPresidi(event);
        let presidiTmp = [];
        utenteProfiloList.map((index) => {
            if ((index.profilo.id === event || event == null) && index.presidio) {
                presidiTmp.push({
                    'descrizione': index.presidio.descrizione,
                    'codice': index.presidio.codice,
                    'id': index.idUtenteProflo,
                });
            }
        });
        setPresidioProfiloList(presidiTmp);
        setProfiloPresidiEnabled(false);
        if (event) {
            let payload = {
            };
            wait(tipologiaService.getAziende(payload))
                .then((response) => {
                    let aziendeTmp = [];
                    response.data.data.map((index) => {
                        aziendeTmp.push({
                            'label': index.descrizione,
                            'value': index.id
                        });
                    });
                    setAziendaCombo2(aziendeTmp);
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setAlert({
                            'state': true,
                            'text': error.response.data.apierror.debugMessage,
                            'type': 'danger'
                        });
                        return;
                    } else {
                        setAlert({
                            'state': true,
                            'text': "Errore durante l'operazione",
                            'type': 'danger'
                        });
                        return;
                    }
                });
        } else {
            setAzienda2(null);
            setPresidio2(null);
            setAziendaCombo2([]);
            setPresidioCombo2([]);
        }
    }

    const onChangeProfiloStrutturePresidio = (event) => {
        setProfiloStrutturePresidio(event);
        let struttureTmp = [];
        utenteProfiloList.map((index) => {
            if ((index.profilo.id === event || event == null) && index.struttura && index.struttura.desPresidio) {
                struttureTmp.push({
                    'descrizione': index.struttura.descrizione,
                    'codice': index.struttura.codice,
                    'presidio': index.struttura.desPresidio,
                    'id': index.idUtenteProflo,
                });
            }
        });
        setStrutturaProfiloPresidioList(struttureTmp);
        setProfiloStrutturePresidioEnabled(false);
        if (event) {
            let payload = {
            };
            wait(tipologiaService.getAziende(payload))
                .then((response) => {
                    let aziendeTmp = [];
                    response.data.data.map((index) => {
                        aziendeTmp.push({
                            'label': index.descrizione,
                            'value': index.id
                        });
                    });
                    setAziendaCombo4(aziendeTmp);
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setAlert({
                            'state': true,
                            'text': error.response.data.apierror.debugMessage,
                            'type': 'danger'
                        });
                        return;
                    } else {
                        setAlert({
                            'state': true,
                            'text': "Errore durante l'operazione",
                            'type': 'danger'
                        });
                        return;
                    }
                });
        } else {
            setAzienda4(null);
            setPresidio4(null);
            setStruttura4(null);
            setAziendaCombo4([]);
            setPresidioCombo4([]);
            setStrutturaCombo4([]);
        }
    }


    const onChangeProfiloStrutture = (event) => {
        setProfiloStrutture(event);
        let struttureTmp = [];
        utenteProfiloList.map((index) => {
            if ((index.profilo.id === event || event == null) && index.struttura && index.struttura.desAzienda) {
                struttureTmp.push({
                    'descrizione': index.struttura.descrizione,
                    'codice': index.struttura.codice,
                    'desAzienda': index.struttura.desAzienda,
                    'id': index.idUtenteProflo,
                });
            }
        });
        setStrutturaProfiloList(struttureTmp);
        setProfiloStruttureEnabled(false);
        if (event) {
            let payload = {
            };
            wait(tipologiaService.getAziende(payload))
                .then((response) => {
                    let aziendeTmp = [];
                    response.data.data.map((index) => {
                        aziendeTmp.push({
                            'label': index.descrizione,
                            'value': index.id
                        });
                    });
                    setAziendaCombo3(aziendeTmp);
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setAlert({
                            'state': true,
                            'text': error.response.data.apierror.debugMessage,
                            'type': 'danger'
                        });
                        return;
                    } else {
                        setAlert({
                            'state': true,
                            'text': "Errore durante l'operazione",
                            'type': 'danger'
                        });
                        return;
                    }
                });
        } else {
            setAzienda3(null);
            setPresidio3(null);
            setStruttura3(null);
            setAziendaCombo3([]);
            setPresidioCombo3([]);
            setStrutturaCombo3([]);
        }
    }

    const handleDeleteToggleAssocia = (item) => {
        let payload = {
            idUtenteProfilo: item.id
        };
        wait(utentiService.deleteAssocia(payload))
            .then((result) => {
                wait(tipologiaService.getProfili({}))
                    .then((response) => {
                        let profiloTmp = [];
                        response.data.data.map((index) => {
                            profiloTmp.push({
                                'label': index.descrizione,
                                'value': index.id
                            });
                        });
                        setProfiloList(profiloTmp);
                    });
                wait(utentiService.getUtente(location.state.id))
                    .then((response) => {
                        if (response && response.data) {
                            buildUtente(response.data);
                            setAlert({
                                'state': false,
                                'text': "",
                                'type': ''
                            });
                        }
                    }).catch((error) => {
                        if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                            setAlert({
                                'state': true,
                                'text': error.response.data.apierror.debugMessage,
                                'type': 'danger'
                            });
                            return;
                        } else {
                            setAlert({
                                'state': true,
                                'text': "Errore durante l'operazione",
                                'type': 'danger'
                            });
                            return;
                        }
                    });
                // navigate("/utenti");
                // setIsPageChanged(false);
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                    setAlert({
                        'state': true,
                        'text': error.response.data.apierror.debugMessage,
                        'type': 'danger'
                    });
                    return;
                } else {
                    setAlert({
                        'state': true,
                        'text': "Errore durante l'operazione",
                        'type': 'danger'
                    });
                    return;
                }
            });
        if (azienda1)
            setProfiloAziende(null);
        if (presidio2)
            setProfiloPresidi(null);
        if (struttura3)
            setProfiloStrutture(null)
    }

    const handleDeleteToggleProfilo = (item) => {
        let payload = {
            idUtente: idUtente,
            idProfile: item.id,
        };
        wait(utentiService.deleteAssociaUtenti(payload))
            .then((result) => {
                wait(tipologiaService.getProfili({}))
                    .then((response) => {
                        let profiloTmp = [];
                        response.data.data.map((index) => {
                            profiloTmp.push({
                                'label': index.descrizione,
                                'value': index.id
                            });
                        });
                        setProfiloList(profiloTmp);
                    });
                wait(utentiService.getUtente(location.state.id))
                    .then((response) => {
                        if (response && response.data) {
                            buildUtente(response.data);
                            setAlert({
                                'state': false,
                                'text': "",
                                'type': ''
                            });
                        }
                    }).catch((error) => {
                        if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                            setAlert({
                                'state': true,
                                'text': error.response.data.apierror.debugMessage,
                                'type': 'danger'
                            });
                            return;
                        } else {
                            setAlert({
                                'state': true,
                                'text': "Errore durante l'operazione",
                                'type': 'danger'
                            });
                            return;
                        }
                    });
                // navigate("/utenti");
                // setIsPageChanged(false);
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                    setAlert({
                        'state': true,
                        'text': error.response.data.apierror.debugMessage,
                        'type': 'danger'
                    });
                    return;
                } else {
                    setAlert({
                        'state': true,
                        'text': "Errore durante l'operazione",
                        'type': 'danger'
                    });
                    return;
                }
            });
    }

    return (<Container fluid>
        <CustomBreadCrumb
            first={{
                "name": "Elenco Utenti",
                "onClick": () => { navigate(-1) },
                "active": false
            }}
            second={{
                "name": "Utente",
                "onClick": () => { },
                "active": true
            }}
        />
        <Accordion>
            <AccordionHeader
                className="d-none d-lg-block accordionButton"
                active={collapseOpen1}
                onToggle={() => setCollapseOpen1(!collapseOpen1)}
            >
                Utente
            </AccordionHeader>
            <AccordionBody active={collapseOpen1}>
                <Row style={{ marginTop: '4vh' }}>
                    <Col xs={12} md={3}  >
                        <Input
                            type='text'
                            className='clickableLabel'
                            label='Codice Fiscale*'
                            value={codFiscale}
                            onChange={(event) => {
                                setIsPageChanged(true);
                                setCodFiscale(event.target.value.toUpperCase());
                                if (!event.target.value) {
                                    setCodFiscaleError(true);
                                    setCodFiscaleHelper("Campo obbligatorio");
                                }
                            }}
                            infoText={isSalvaClicked && codFiscaleHelper}
                            invalid={isSalvaClicked && codFiscaleError}
                            readOnly={location && location.state && location.state.pageStatus === "detail" ? true : false}
                            maxLength={16}
                        />
                    </Col>
                </Row>

            </AccordionBody>
            {location && location.state && location.state.pageStatus === "insert" ? ("") : <>
                <AccordionHeader
                    className='accordionButton'
                    active={collapseOpen2}
                    onToggle={() => setCollapseOpen2(!collapseOpen2)}
                >
                    Profili
                </AccordionHeader>
                <div style={{ height: "3vh" }}></div>
                <AccordionBody active={collapseOpen2}>
                    <Row className='d-flex justify-content-end'>
                        {!dettaglioPage && <>
                            <Col xs={12} sm={6} xl={3}>
                                <div
                                    class='form-group'
                                    onClick={() => {

                                        setProfiloEnabled(true);
                                        document.getElementById('select profilo').focus();
                                    }}
                                >
                                    <label
                                        htmlFor='selectExampleClassic'
                                        class={(isProfiloEnabled || profilo != null) ? 'active' : ''}
                                        style={{ cursor: 'context-menu' }}
                                    >
                                        Profilo</label>
                                    <Select
                                        id='selectProfilo'
                                        inputId='select profilo'
                                        onBlur={() => { setProfiloEnabled(false); }}
                                        isClearable={true}
                                        placeholder=''
                                        aria-label='Scegli una opzione'
                                        isSearchable={false}
                                        options={profiloList}
                                        onChange={(event) => { onChangeProfilo(event ? event.value : null); }}
                                        styles={customStylesInputSelect}
                                        menuPortalTarget={document.body}
                                    //menuIsOpen={isProfiloEnabled}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} xl={2}>
                                <Button className="buttonPrimary"
                                    id='inserisci_btn_profili'
                                    color='primary'
                                    block
                                    onClick={() => {
                                        handleAssociaProfilo();
                                    }}
                                >
                                    INSERISCI
                                </Button>{' '}
                            </Col></>}
                        <Tooltip
                            placement='top'
                            isOpen={inserisciTooltip}
                            target={'inserisci_btn_profili'}
                            toggle={() => setInserisciTooltip(!inserisciTooltip)}
                        >
                            Associa profilo
                        </Tooltip>
                    </Row>
                    {
                        profiliList && profiliList.length !== 0 ? (<Row className='d-flex justify-content-end'>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th scope='col'>Nome Profilo</th>
                                        <th scope='col'>Codice Profilo</th>
                                        <th scope='col' class="text-center">Azioni</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {profiliList.map((item, index) => (
                                        <tr>
                                            <td>{item.codice}</td>
                                            <td>{item.descrizione}</td>
                                            <td class="text-center">
                                                {!dettaglioPage && <>
                                                    <Button title="Elimina" onClick={() => {
                                                        handleDeleteToggleProfilo(item);
                                                    }}>  <Icon icon={"it-delete"} /></Button></>}
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </Table></Row>) : (<Row className='d-flex justify-content-center'><p>Nessun risultato trovato</p></Row>)
                    }
                </AccordionBody>
            </>}

            {location && location.state && location.state.pageStatus === "insert" ? ("") : <>
                <AccordionHeader
                    className='accordionButton'
                    active={collapseOpen3}
                    onToggle={() => setCollapseOpen3(!collapseOpen3)}
                >
                    Aziende
                </AccordionHeader>
                <div style={{ height: "3vh" }}></div>
                <AccordionBody active={collapseOpen3}>
                    <Row className='d-flex justify-content-end'>
                        {!dettaglioPage && <>
                            <Col xs={12} sm={6} xl={3} >
                                <div
                                    class='form-group'
                                    onClick={() => {
                                        setProfiloAziendeEnabled(true);
                                        document.getElementById('select profilo aziende').focus();
                                    }}
                                >
                                    <label
                                        htmlFor='selectExampleClassic'
                                        class={(isProfiloAziendeEnabled || profiloAziende != null) ? 'active' : ''}
                                        style={{ cursor: 'context-menu' }}
                                    >
                                        Profilo</label>
                                    <Select
                                        id='selectProfiloAziende'
                                        inputId='select profilo aziende'
                                        onBlur={() => { setProfiloAziendeEnabled(false); }}
                                        isClearable={true}
                                        placeholder=''
                                        aria-label='Scegli una opzione'
                                        isSearchable={false}
                                        options={profiliCombo}
                                        onChange={(event) => { onChangeProfiloAziende(event ? event.value : null); }}
                                        styles={customStylesInputSelect}
                                        menuPortalTarget={document.body}
                                    //menuIsOpen={isProfiloAziendeEnabled}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} xl={3}>
                                <div
                                    class='form-group'
                                    onClick={() => {
                                        setAzienda1Enabled(true);
                                        document.getElementById('select azienda1').focus();
                                    }}
                                >
                                    <label
                                        htmlFor='selectExampleClassic'
                                        class={(isAzienda1Enabled || azienda1 != null) ? 'active' : ''}
                                        style={{ cursor: 'context-menu' }}
                                    >
                                        Aziende</label>
                                    <Select
                                        id='selectAzienda1'
                                        inputId='select azienda1'
                                        onBlur={() => { setAzienda1Enabled(false); }}
                                        isClearable={true}
                                        placeholder=''
                                        aria-label='Scegli una opzione'
                                        isSearchable={false}
                                        options={aziendaCombo1}
                                        onChange={(event) => { onChangeAzienda1(event ? event.value : null); }}
                                        styles={customStylesInputSelect}
                                        menuPortalTarget={document.body}
                                        //menuIsOpen={isAzienda1Enabled}
                                        isDisabled={!profiloAziende}
                                    /></div></Col>                         </>}
                    </Row>
                    <Row className='d-flex justify-content-end'>
                        {!dettaglioPage && <>
                            <Col xs={12} xl={2}>
                                <Button className="buttonPrimary"
                                    id='inserisci_btn_azienda'
                                    color='primary'
                                    block
                                    onClick={() => {
                                        handleAssociaAzienda();
                                    }}
                                    disabled={!azienda1}
                                >
                                    Associa azienda
                                </Button>{' '}
                            </Col>
                        </>
                        }
                    </Row>
                    {
                        aziendaProfiloList && aziendaProfiloList.length !== 0 ? (<Row className='d-flex justify-content-end'>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th scope='col'>Nome Azienda</th>
                                        <th scope='col'>Codice Azienda</th>
                                        <th scope='col'>Profilo</th>
                                        <th scope='col' class="text-center">Azioni</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {aziendaProfiloList.map((item, index) => (
                                        <tr>
                                            <td>{item.descrizione}</td>
                                            <td>{item.codice}</td>
                                            <td>{item.profilo}</td>
                                            <td class="text-center">
                                                {!dettaglioPage && <>
                                                    <Button title="Elimina" onClick={() => {
                                                        handleDeleteToggleAssocia(item);
                                                    }}>  <Icon icon={"it-delete"} /></Button></>}
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </Table></Row>) : (<Row className='d-flex justify-content-center'><p>Nessun risultato trovato</p></Row>)
                    }
                </AccordionBody>
            </>}


            {location && location.state && location.state.pageStatus === "insert" ? ("") : <>
                <AccordionHeader
                    className='accordionButton'
                    active={collapseOpen4}
                    onToggle={() => setCollapseOpen4(!collapseOpen4)}
                >
                    Presidi
                </AccordionHeader>
                <div style={{ height: "3vh" }}></div>
                <AccordionBody active={collapseOpen4}>
                    <Row className='d-flex justify-content-end'>
                        {!dettaglioPage && <>
                            <Col xs={12} sm={6} xl={3}>
                                <div
                                    class='form-group'
                                    onClick={() => {
                                        setProfiloPresidiEnabled(true);
                                        document.getElementById('select profilo presidi').focus();
                                    }}
                                >
                                    <label
                                        htmlFor='selectExampleClassic'
                                        class={(isProfiloPresidiEnabled || profiloPresidi != null) ? 'active' : ''}
                                        style={{ cursor: 'context-menu' }}
                                    >
                                        Profilo</label>
                                    <Select
                                        id='selectProfiloPresidi'
                                        inputId='select profilo presidi'
                                        onBlur={() => { setProfiloPresidiEnabled(false); }}
                                        isClearable={true}
                                        placeholder=''
                                        aria-label='Scegli una opzione'
                                        isSearchable={false}
                                        options={profiliCombo}
                                        onChange={(event) => { onChangeProfiloPresidi(event ? event.value : null); }}
                                        styles={customStylesInputSelect}
                                        menuPortalTarget={document.body}
                                    //menuIsOpen={isProfiloPresidiEnabled}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} xl={3}>
                                <div
                                    class='form-group'
                                    onClick={() => {
                                        setAzienda2Enabled(true);
                                        document.getElementById('select azienda2').focus();
                                    }}
                                >
                                    <label
                                        htmlFor='selectExampleClassic'
                                        class={(isAzienda2Enabled || azienda2 != null) ? 'active' : ''}
                                        style={{ cursor: 'context-menu' }}
                                    >
                                        Aziende</label>
                                    <Select
                                        id='selectAzienda2'
                                        inputId='select azienda2'
                                        onBlur={() => { setAzienda2Enabled(false); }}
                                        isClearable={true}
                                        placeholder=''
                                        aria-label='Scegli una opzione'
                                        isSearchable={false}
                                        options={aziendaCombo2}
                                        onChange={(event) => { onChangeAzienda2(event ? event.value : null); }}
                                        styles={customStylesInputSelect}
                                        menuPortalTarget={document.body}
                                        //menuIsOpen={isAzienda2Enabled}
                                        isDisabled={!profiloPresidi}
                                    /></div></Col>
                            <Col xs={12} xl={3}>
                                <div
                                    class='form-group'
                                    onClick={() => {
                                        setPresidio2Enabled(true);
                                        document.getElementById('select presidio2').focus();
                                    }}
                                >  <label
                                    htmlFor='selectExampleClassic'
                                    class={(isPresidio2Enabled || presidio2 != null) ? 'active' : ''}
                                    style={{ cursor: 'context-menu' }}
                                >
                                        Presidio</label>
                                    <Select
                                        id='selectPresidio2'
                                        inputId='select presidio2'
                                        onBlur={() => { setPresidio2Enabled(false); }}
                                        isClearable={true}
                                        placeholder=''
                                        aria-label='Scegli una opzione'
                                        isSearchable={false}
                                        options={presidioCombo2}
                                        onChange={(event) => { onChangePresidio2(event ? event.value : null); }}
                                        styles={customStylesInputSelect}
                                        menuPortalTarget={document.body}
                                        //menuIsOpen={isPresidio2Enabled}
                                        isDisabled={!azienda2}
                                    /></div></Col>                         </>}
                    </Row>
                    <Row className='d-flex justify-content-end'>
                        {!dettaglioPage && <>
                            <Col xs={12} xl={2}>
                                <Button className="buttonPrimary"
                                    id='inserisci_btn_presidio'
                                    color='primary'
                                    block
                                    onClick={() => {
                                        handleAssociaPresidio();
                                    }}
                                    disabled={!presidio2}
                                >
                                    Associa presidio
                                </Button>{' '}
                            </Col>
                        </>
                        }
                    </Row>
                    {
                        presidioProfiloList && presidioProfiloList.length !== 0 ? (<Row className='d-flex justify-content-end'>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th scope='col'>Nome Presidio</th>
                                        <th scope='col'>Codice Presidio</th>
                                        <th scope='col'>Profilo</th>
                                        <th scope='col' class="text-center">Azioni</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {presidioProfiloList.map((item, index) => (
                                        <tr>
                                            <td>{item.descrizione}</td>
                                            <td>{item.codice}</td>
                                            <td>{item.profilo}</td>
                                            <td class="text-center">
                                                {!dettaglioPage && <>
                                                    <Button title="Elimina" onClick={() => {
                                                        handleDeleteToggleAssocia(item);
                                                    }}>  <Icon icon={"it-delete"} /></Button></>}
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </Table></Row>) : (<Row className='d-flex justify-content-center'><p>Nessun risultato trovato</p></Row>)
                    }
                </AccordionBody>
            </>}



            {location && location.state && location.state.pageStatus === "insert" ? ("") : <>
                <AccordionHeader
                    className='accordionButton'
                    active={collapseOpen5}
                    onToggle={() => setCollapseOpen5(!collapseOpen5)}
                >
                    Ambulatori
                </AccordionHeader>
                <div style={{ height: "3vh" }}></div>
                <AccordionBody active={collapseOpen5}>
                    <Row className='d-flex justify-content-end'>
                        {!dettaglioPage && <>
                            <Col xs={12} sm={6} xl={3}>
                                <div
                                    class='form-group'
                                    onClick={() => {
                                        setProfiloStruttureEnabled(true);
                                        document.getElementById('select profilo strutture').focus();
                                    }}
                                >
                                    <label
                                        htmlFor='selectExampleClassic'
                                        class={(isProfiloStruttureEnabled || profiloStrutture != null) ? 'active' : ''}
                                        style={{ cursor: 'context-menu' }}
                                    >
                                        Profilo</label>
                                    <Select
                                        id='selectProfiloStrutture'
                                        inputId='select profilo strutture'
                                        onBlur={() => { setProfiloStruttureEnabled(false); }}
                                        isClearable={true}
                                        placeholder=''
                                        aria-label='Scegli una opzione'
                                        isSearchable={false}
                                        options={profiliCombo}
                                        onChange={(event) => { onChangeProfiloStrutture(event ? event.value : null); }}
                                        styles={customStylesInputSelect}
                                        menuPortalTarget={document.body}
                                    //menuIsOpen={isProfiloStruttureEnabled}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} xl={3}>
                                <div
                                    class='form-group'
                                    onClick={() => {
                                        setAzienda3Enabled(true);
                                        document.getElementById('select azienda3').focus();
                                    }}
                                >
                                    <label
                                        htmlFor='selectExampleClassic'
                                        class={(isAzienda3Enabled || azienda3 != null) ? 'active' : ''}
                                        style={{ cursor: 'context-menu' }}
                                    >
                                        Aziende</label>
                                    <Select
                                        id='selectAzienda3'
                                        inputId='select azienda3'
                                        onBlur={() => { setAzienda3Enabled(false); }}
                                        isClearable={true}
                                        placeholder=''
                                        aria-label='Scegli una opzione'
                                        isSearchable={false}
                                        options={aziendaCombo3}
                                        onChange={(event) => { onChangeAzienda3(event ? event.value : null); }}
                                        styles={customStylesInputSelect}
                                        menuPortalTarget={document.body}
                                        //menuIsOpen={isAzienda3Enabled}
                                        isDisabled={!profiloStrutture}
                                    /></div></Col>
                            {/* <Col xs={12} xl={3}>
                                <div
                                    class='form-group'
                                    onClick={() => {
                                        setPresidio3Enabled(true);
                                        document.getElementById('select presidio3').focus();
                                    }}
                                >  <label
                                    htmlFor='selectExampleClassic'
                                    class={(isPresidio3Enabled || presidio3 != null) ? 'active' : ''}
                                    style={{ cursor: 'context-menu' }}
                                >
                                        Presidio</label>
                                    <Select
                                        id='selectPresidio3'
                                        inputId='select presidio3'
                                        onBlur={() => { setPresidio3Enabled(false); }}
                                        isClearable={true}
                                        placeholder=''
                                        aria-label='Scegli una opzione'
                                        isSearchable={false}
                                        options={presidioCombo3}
                                        onChange={(event) => { onChangePresidio3(event ? event.value : null); }}
                                        styles={customStylesInputSelect}
                                        menuPortalTarget={document.body}
                                        //menuIsOpen={isPresidio3Enabled}
                                        isDisabled={!azienda3}
                                    /></div></Col> */}
                            <Col xs={12} xl={3}>
                                <div
                                    class='form-group'
                                    onClick={() => {
                                        setStruttura3Enabled(true);
                                        document.getElementById('select strutture3').focus();
                                    }}
                                > <label
                                    htmlFor='selectExampleClassic'
                                    class={(isStruttura3Enabled || struttura3 != null) ? 'active' : ''}
                                    style={{ cursor: 'context-menu' }}
                                >
                                        Strutture</label>
                                    <Select
                                        id='selectStrutture3'
                                        inputId='select strutture3'
                                        onBlur={() => { setStruttura3Enabled(false); }}
                                        isClearable={true}
                                        placeholder=''
                                        aria-label='Scegli una opzione'
                                        isSearchable={false}
                                        options={strutturaCombo3}
                                        onChange={(event) => { onChangeStruttura3(event ? event.value : null); }}
                                        styles={customStylesInputSelect}
                                        menuPortalTarget={document.body}
                                        //menuIsOpen={isStruttura3Enabled}
                                        isDisabled={!azienda3}
                                    /></div></Col></>}
                    </Row>
                    <Row className='d-flex justify-content-end'>
                        {!dettaglioPage && <>
                            <Col xs={12} xl={2}>
                                <Button className="buttonPrimary"
                                    id='inserisci_btn_struttura'
                                    color='primary'
                                    block
                                    onClick={() => {
                                        handleAssociaAmbulatorio();
                                    }}
                                    disabled={!struttura3}
                                >
                                    Associa ambulatorio
                                </Button>{' '}
                            </Col>
                        </>
                        }
                    </Row>
                    {
                        strutturaProfiloList && strutturaProfiloList.length !== 0 ? (<Row className='d-flex justify-content-end'>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th scope='col'>Nome Struttura</th>
                                        <th scope='col'>Codice Struttura</th>
                                        <th scope='col'>Profilo</th>
                                        <th scope='col'>Azienda</th>
                                        <th scope='col' class="text-center">Azioni</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {strutturaProfiloList.map((item, index) => (
                                        <tr>
                                            <td>{item.descrizione}</td>
                                            <td>{item.codice}</td>
                                            <td>{item.profilo}</td>
                                            <td>{item.desAzienda}</td>
                                            <td class="text-center">
                                                {!dettaglioPage && <>
                                                    <Button title="Elimina" onClick={() => {
                                                        handleDeleteToggleAssocia(item);
                                                    }}>  <Icon icon={"it-delete"} /></Button></>}
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </Table></Row>) : (<Row className='d-flex justify-content-center'><p>Nessun risultato trovato</p></Row>)
                    }
                </AccordionBody>
            </>}

            {location && location.state && location.state.pageStatus === "insert" ? ("") : <>
                <AccordionHeader
                    className='accordionButton'
                    active={collapseOpen6}
                    onToggle={() => setCollapseOpen6(!collapseOpen6)}
                >
                    Reparti
                </AccordionHeader>
                <div style={{ height: "3vh" }}></div>
                <AccordionBody active={collapseOpen6}>
                    <Row className='d-flex justify-content-end'>
                        {!dettaglioPage && <>
                            <Col xs={12} sm={6} xl={3}>
                                <div
                                    class='form-group'
                                    onClick={() => {
                                        setProfiloStrutturePresidioEnabled(true);
                                        document.getElementById('select profilo strutture presidio').focus();
                                    }}
                                >
                                    <label
                                        htmlFor='selectExampleClassic'
                                        class={(isProfiloStrutturePresidioEnabled || profiloStrutturePresidio != null) ? 'active' : ''}
                                        style={{ cursor: 'context-menu' }}
                                    >
                                        Profilo</label>
                                    <Select
                                        id='selectProfiloStrutturePresidio'
                                        inputId='select profilo strutture presidio'
                                        onBlur={() => { setProfiloStrutturePresidioEnabled(false); }}
                                        isClearable={true}
                                        placeholder=''
                                        aria-label='Scegli una opzione'
                                        isSearchable={false}
                                        options={profiliCombo}
                                        onChange={(event) => { onChangeProfiloStrutturePresidio(event ? event.value : null); }}
                                        styles={customStylesInputSelect}
                                        menuPortalTarget={document.body}
                                    //menuIsOpen={isProfiloStrutturePresidioEnabled}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} xl={3}>
                                <div
                                    class='form-group'
                                    onClick={() => {
                                        setAzienda4Enabled(true);
                                        document.getElementById('select azienda4').focus();
                                    }}
                                >
                                    <label
                                        htmlFor='selectExampleClassic'
                                        class={(isAzienda4Enabled || azienda4 != null) ? 'active' : ''}
                                        style={{ cursor: 'context-menu' }}
                                    >
                                        Aziende</label>
                                    <Select
                                        id='selectAzienda4'
                                        inputId='select azienda4'
                                        onBlur={() => { setAzienda4Enabled(false); }}
                                        isClearable={true}
                                        placeholder=''
                                        aria-label='Scegli una opzione'
                                        isSearchable={false}
                                        options={aziendaCombo4}
                                        onChange={(event) => { onChangeAzienda4(event ? event.value : null); }}
                                        styles={customStylesInputSelect}
                                        menuPortalTarget={document.body}
                                        //menuIsOpen={isAzienda4Enabled}
                                        isDisabled={!profiloStrutturePresidio}
                                    /></div></Col>
                            <Col xs={12} xl={3}>
                                <div
                                    class='form-group'
                                    onClick={() => {
                                        setPresidio4Enabled(true);
                                        document.getElementById('select presidio4').focus();
                                    }}
                                >  <label
                                    htmlFor='selectExampleClassic'
                                    class={(isPresidio4Enabled || presidio4 != null) ? 'active' : ''}
                                    style={{ cursor: 'context-menu' }}
                                >
                                        Presidio</label>
                                    <Select
                                        id='selectPresidio4'
                                        inputId='select presidio4'
                                        onBlur={() => { setPresidio4Enabled(false); }}
                                        isClearable={true}
                                        placeholder=''
                                        aria-label='Scegli una opzione'
                                        isSearchable={false}
                                        options={presidioCombo4}
                                        onChange={(event) => { onChangePresidio4(event ? event.value : null); }}
                                        styles={customStylesInputSelect}
                                        menuPortalTarget={document.body}
                                        //menuIsOpen={isPresidio4Enabled}
                                        isDisabled={!azienda4}
                                    /></div></Col>
                            <Col xs={12} xl={3}>
                                <div
                                    class='form-group'
                                    onClick={() => {
                                        setStruttura4Enabled(true);
                                        document.getElementById('select strutture4').focus();
                                    }}
                                > <label
                                    htmlFor='selectExampleClassic'
                                    class={(isStruttura4Enabled || struttura4 != null) ? 'active' : ''}
                                    style={{ cursor: 'context-menu' }}
                                >
                                        Strutture</label>
                                    <Select
                                        id='selectStrutture4'
                                        inputId='select strutture4'
                                        onBlur={() => { setStruttura4Enabled(false); }}
                                        isClearable={true}
                                        placeholder=''
                                        aria-label='Scegli una opzione'
                                        isSearchable={false}
                                        options={strutturaCombo4}
                                        onChange={(event) => { onChangeStruttura4(event ? event.value : null); }}
                                        styles={customStylesInputSelect}
                                        menuPortalTarget={document.body}
                                        //menuIsOpen={isStruttura4Enabled}
                                        isDisabled={!presidio4 || !azienda4}
                                    /></div></Col></>}
                    </Row>
                    <Row className='d-flex justify-content-end'>
                        {!dettaglioPage && <>
                            <Col xs={12} xl={2}>
                                <Button className="buttonPrimary"
                                    id='inserisci_btn_struttura'
                                    color='primary'
                                    block
                                    onClick={() => {
                                        handleAssociaReparto();
                                    }}
                                    disabled={!struttura4}
                                >
                                    Associa reparto
                                </Button>{' '}
                            </Col>
                        </>
                        }
                    </Row>
                    {
                        strutturaProfiloPresidioList && strutturaProfiloPresidioList.length !== 0 ? (<Row className='d-flex justify-content-end'>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th scope='col'>Nome Struttura</th>
                                        <th scope='col'>Codice Struttura</th>
                                        <th scope='col'>Profilo</th>
                                        <th scope='col'>Presidio</th>
                                        <th scope='col' class="text-center">Azioni</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {strutturaProfiloPresidioList.map((item, index) => (
                                        <tr>
                                            <td>{item.descrizione}</td>
                                            <td>{item.codice}</td>
                                            <td>{item.profilo}</td>
                                            <td>{item.desPresidio}</td>
                                            <td class="text-center">
                                                {!dettaglioPage && <>
                                                    <Button title="Elimina" onClick={() => {
                                                        handleDeleteToggleAssocia(item);
                                                    }}>  <Icon icon={"it-delete"} /></Button></>}
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </Table></Row>) : (<Row className='d-flex justify-content-center'><p>Nessun risultato trovato</p></Row>)
                    }
                </AccordionBody>
            </>}
        </Accordion>
        <LinkList>
            <LinkListItem divider />
        </LinkList>
        <Container fluid style={{ marginBottom: "3%" }}>
            <Row className="alignElementsCenterAndEnd" style={{ display: "flex" }}>
                <Col xs={10} sm={8} lg={3} xl={2} style={{ display: "flex", margin: "5px 0px" }}>
                    <Button className="buttonPrimary" onClick={() => {
                        if (!dettaglioPage) {
                            if (!isPageChanged) {
                                navigate(-1);
                            } else {
                                toggleModalIndietro(!isOpenIndietro);
                                if (isOpenIndietro) {
                                    navigate(-1);
                                }
                            }
                        } else {
                            navigate(-1);
                        }
                    }} id='indietro_btn'>INDIETRO</Button>
                    <Modal
                        isOpen={isOpenIndietro}
                        toggle={() => toggleModalIndietro(!isOpenIndietro)}
                    >
                        <ModalHeader toggle={() => toggleModalIndietro(!isOpenIndietro)} id='esempio12'>
                            Indietro
                        </ModalHeader>
                        <ModalBody>
                            <p>Tutte le modifiche andranno perse. Sei sicuro di voler continuare?</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button className="buttonPrimary" onClick={() => toggleModalIndietro(!isOpenIndietro)}>
                                Annulla
                            </Button>
                            <Button className="buttonPrimary" onClick={() => navigate(-1)}>
                                Conferma
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Col>
                {!dettaglioPage &&
                    <Col xs={10} sm={8} lg={3} xl={2} style={{ display: "flex", margin: "5px 0px" }}>
                        <Button
                            className="buttonPrimary"
                            onClick={() => {
                                if (!isPageChanged) {
                                    confirmSalvaClick()
                                } else {
                                    toggleModalSalva(true)
                                }
                            }}
                            id='salva_btn'>SALVA</Button>
                        <Modal
                            isOpen={isOpenSalva}
                            toggle={() => toggleModalSalva(!isOpenSalva)}
                        >
                            <ModalHeader toggle={() => toggleModalSalva(!isOpenSalva)} id='esempio12'>
                                Conferma
                            </ModalHeader>
                            <ModalBody>
                                <p>Confermi il salvataggio delle modifiche?</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button className="buttonPrimary" onClick={() => toggleModalSalva(false)}>
                                    Annulla
                                </Button>
                                <Button className="buttonPrimary" onClick={confirmSalvaClick}>
                                    Conferma
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Col>}
            </Row>
        </Container>
        {alert.state &&
            <div>
                <div className='modal-content'>
                    <Modal fade centered isOpen={alert.state}>
                        <ModalHeader>Errore</ModalHeader>
                        <ModalBody>{alert.text}</ModalBody>
                        <ModalFooter>
                            <Button className="buttonPrimary" onClick={() => {
                                setAlert({
                                    'state': false,
                                    'text': "",
                                    'type': ''
                                });
                            }} color='secondary'>Chiudi</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        }
        <LinkList>
            <LinkListItem divider />
        </LinkList>
    </Container >
    )
}

export default GestioneUtente;