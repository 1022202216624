import React, { memo } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import ProfiloPage from "./profili/ProfiloPage";
import GestioneProfilo from "./profili/GestioneProfilo";
import Page from "./scheleton/page"
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import GestioneUtente from "./utenti/GestioneUtente";
import UtentePage from "./utenti/UtentePage";
import { useState, useEffect } from 'react';
import {TimeoutModal} from "./custom/TimeoutModal";

const Root = React.memo(function (props) {
 
  const { keycloak } = useKeycloak();
  const [openDialog, setOpenDialog] = useState(false);

  axios.interceptors.request.use((request) => {
    if (keycloak.token) {
      request.headers.common.Authorization = `Bearer ${keycloak.token}`;
    }
    return request;
  },
    (err) => {
      return Promise.reject(err);
    }
  );

  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];
  localStorage.setItem('lastActvity', new Date());
  events.forEach(event => {
      document.addEventListener(event,
      () =>{ 
        localStorage.setItem('lastActvity', new Date());
      })
  ;});

  let inactivityFunction = () => {
    if(openDialog) {
      setOpenDialog(false);
      keycloak.logout();
    }
  }

  const [counter, setCounter] = useState(30);

  useEffect(() => {
      if(openDialog){
         const timer = setTimeout(() => {
              inactivityFunction();
          }, 30000);
         return () => clearTimeout(timer);
      }
  }, 
  [openDialog]);

  useEffect(() => {

  let timeInterval = setInterval(() => {
   let lastAcivity = localStorage.getItem('lastActvity')
        let diffMs = Math.abs(new Date(lastAcivity) - new Date()); // milliseconds between now & last activity
        let seconds = Math.floor((diffMs/1000));
        let minute = Math.floor((seconds/60));
        // check if the seconds are equal to 29 minutes and 30 seconds
        if(seconds === 870){
          setOpenDialog(true);
        }
  }, 1000);
    return () => clearInterval(timeInterval);
  }, []);

  return (
    <>
    <Routes>
      <Route path="profili" element={keycloak.authenticated && <ProfiloPage isPageChanged={props.isPageChanged} setIsPageChanged={props.setIsPageChanged} />} />
      <Route path="gestione-profili" element={keycloak.authenticated && <GestioneProfilo isPageChanged={props.isPageChanged} setIsPageChanged={props.setIsPageChanged} />} />

      <Route path="utenti" element={keycloak.authenticated && <UtentePage isPageChanged={props.isPageChanged} setIsPageChanged={props.setIsPageChanged} />} />
      <Route path="gestione-utenti" element={keycloak.authenticated && <GestioneUtente isPageChanged={props.isPageChanged} setIsPageChanged={props.setIsPageChanged} />} />

      <Route path="" element={<Navigate replace to="profili" />} />
    </Routes>
    {openDialog && (
                    <TimeoutModal
                        openDialog={true}
                        toggleDialog={(value) => setOpenDialog(value)}
                        seconds={counter}
                    />
          )}
    </>
  );
});
const RootPage = ({ ...props }) => {
  return <Page body={<Root {...props} />} />;
};
export default memo(RootPage);