import {
    Toggle, Accordion, Alert, AccordionBody, AccordionHeader, Button, Col, Container, Input, LinkList, LinkListItem, Modal, ModalBody, ModalFooter, ModalHeader, Row
} from 'design-react-kit';
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import '../../App.css';
import { isEmpty } from '../common/stringUtils';
import { CustomBreadCrumb } from '../custom/CustomBreadCrumb';
import profiliService from '../service/profiliService';
import { wait } from '../common/Loading';

function GestioneProfilo({ isPageChanged, setIsPageChanged }) {

    const navigate = useNavigate();
    const location = useLocation();

    const [isSalvaClicked, setIsSalvaClicked] = useState(false);

    const [idProfilo, setIdProfilo] = useState(location && location.state && location.state.id ? location.state.id : null);
    const [codProfilo, setCodProfilo] = useState("");
    const [codProfiloHelper, setCodProfiloHelper] = useState("");
    const [codProfiloError, setCodProfiloError] = useState(false);
    const [nomeProfilo, setNomeProfilo] = useState("");
    const [nomeProfiloHelper, setNomeProfiloHelper] = useState("");
    const [nomeProfiloError, setNomeProfiloError] = useState(false);
    const [flgLettura, setFlgLettura] = useState(false);
    const [flgLetturaScrittura, setFlgLetturaScrittura] = useState(false);
    const [flgVisibile, setFlgVisibile] = useState(false);

    const [genericError, setGenericError] = useState(false);
    const [genericErrorMessage, setGenericErrorMessage] = useState("");

    const [isOpenSalva, toggleModalSalva] = useState(false);
    const [isOpenIndietro, toggleModalIndietro] = useState(false);

    const [dettaglioPage, setDettaglioPage] = useState(false);

    const [collapseOpen1, setCollapseOpen1] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (location && location.state && (location.state.pageStatus === "update" || location.state.pageStatus === "detail")) {
            if (location.state.pageStatus === "detail") { setDettaglioPage(true); }
            wait(profiliService.getProfilo(location.state.id))
                .then((response) => {
                    if (response && response.data) {
                        buildProfilo(response.data);
                        setGenericError(false);
                    }
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setGenericError(true);
                        setGenericErrorMessage(error.response.data.apierror.debugMessage);
                        return;
                    } else {
                        setGenericError(true);
                        setGenericErrorMessage("Errore durante l'operazione");
                        return;
                    }
                });
        }
    }, []);


    const buildProfilo = (data) => {
        setNomeProfilo(data.nomeProfilo);
        setNomeProfiloError(data.nomeProfilo === null);
        setNomeProfiloHelper("Campo obbligatorio");
        setCodProfilo(data.codProfilo);
        setCodProfiloError(data.codProfilo === null);
        setCodProfiloHelper("Campo obbligatorio");
        setFlgLettura(data.flgLettura);
        setFlgLetturaScrittura(data.flgLetturaScrittura);
        setFlgVisibile(data.flgVisibile);
        setIdProfilo(data.idProfilo);
    }

    const handleSubmit = () => {
        let error = 0;
        setGenericError(false);
        setGenericErrorMessage("");
        if (isEmpty(nomeProfilo)) {
            setNomeProfiloHelper("Campo obbligatorio");
            setNomeProfiloError(true);
            error++;
        } else {
            setNomeProfiloHelper("");
            setNomeProfiloError(false)
        }
        if (isEmpty(codProfilo)) {
            setCodProfiloHelper("Campo obbligatorio");
            setCodProfiloError(true);
            error++;
        } else {
            setCodProfiloHelper("");
            setCodProfiloError(false);
        }

        if (error < 1) {
            let payload = {
                nomeProfilo: nomeProfilo,
                codProfilo: codProfilo,
                flgLettura: flgLettura,
                flgLetturaScrittura: flgLetturaScrittura,
                flgVisibile: flgVisibile,
                idProfilo: idProfilo,
            };
            wait(profiliService.saveProfilo(payload))
                .then((result) => {
                    navigate("/profili");
                    setIsPageChanged(false);
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setGenericError(true);
                        setGenericErrorMessage(error.response.data.apierror.debugMessage);
                        return;
                    } else {
                        setGenericError(true);
                        setGenericErrorMessage("Errore durante l'operazione");
                        return;
                    }
                });
        } else {
            setIsSalvaClicked(true);
        }
    }

    const confirmSalvaClick = () => {
        handleSubmit();
        toggleModalSalva(false);
    }

    return (<Container fluid>
        <CustomBreadCrumb
            first={{
                "name": "Elenco Profili",
                "onClick": () => { navigate(-1) },
                "active": false
            }}
            second={{
                "name": "Profilo",
                "onClick": () => { },
                "active": true
            }}
        />
        <Accordion>
            <AccordionHeader
                className="d-none d-lg-block accordionButton"
                active={collapseOpen1}
                onToggle={() => setCollapseOpen1(!collapseOpen1)}
            >
                Profilo
            </AccordionHeader>
            <AccordionBody active={collapseOpen1}>
                <Row style={{ marginTop: '4vh' }}>
                    <Col xs={12} md={3}  >
                        <Input
                            type='text'
                            className='clickableLabel'
                            label='Nome Profilo*'
                            value={nomeProfilo}
                            onChange={(event) => {
                                setIsPageChanged(true);
                                setNomeProfilo(event.target.value);
                                if (!event.target.value) {
                                    setNomeProfiloError(true);
                                    setNomeProfiloHelper("Campo obbligatorio");
                                } else if (event.target.value < 3) {
                                    setNomeProfiloError(true);
                                    setNomeProfiloHelper("Inserire almeno tre caratteri");
                                }
                                else {
                                    setNomeProfiloError(false);
                                    setNomeProfiloHelper("");
                                }
                            }}
                            infoText={isSalvaClicked && nomeProfiloHelper}
                            invalid={isSalvaClicked && nomeProfiloError}
                            readOnly={location && location.state && location.state.pageStatus === "detail" ? true : false}
                            maxLength={100}
                        />
                    </Col>
                    <Col xs={12} md={3}  >
                        <Input
                            type='text'
                            className='clickableLabel'
                            label='Codice Profilo*'
                            value={codProfilo}
                            onChange={(event) => {
                                setIsPageChanged(true);
                                setCodProfilo(event.target.value);
                                if (!event.target.value) {
                                    setCodProfiloError(true);
                                    setCodProfiloHelper("Campo obbligatorio");
                                } else {
                                    setCodProfiloError(false);
                                    setCodProfiloHelper("");
                                }
                            }}
                            infoText={isSalvaClicked && codProfiloHelper}
                            invalid={isSalvaClicked && codProfiloError}
                            readOnly={location && location.state && location.state.pageStatus === "detail" ? true : false}
                            maxLength={100}
                        />
                    </Col>
                    <Col xs={12} md={5}>
                        <Toggle label="Lettura" disabled={location && location.state && location.state.pageStatus === "detail" ? true : false} checked={flgLettura} onChange={(e) => setFlgLettura(e.target.checked)} />
                        <Toggle label="Lettura Scrittura" disabled={location && location.state && location.state.pageStatus === "detail" ? true : false} checked={flgLetturaScrittura} onChange={(e) => setFlgLetturaScrittura(e.target.checked)} />
                        <Toggle label="Attivo" disabled={location && location.state && location.state.pageStatus === "detail" ? true : false} checked={flgVisibile} onChange={(e) => setFlgVisibile(e.target.checked)} />
                    </Col>
                </Row>

            </AccordionBody>
        </Accordion>
        <LinkList>
            <LinkListItem divider />
        </LinkList>
        {
            (genericError) && <Alert color='danger' className='display-linebreak'>{genericErrorMessage}</Alert>
        }
        <Container fluid style={{ marginBottom: "3%" }}>
            <Row className="alignElementsCenterAndEnd" style={{ display: "flex" }}>
                <Col xs={10} sm={8} lg={3} xl={2} style={{ display: "flex", margin: "5px 0px" }}>
                    <Button className="buttonPrimary" onClick={() => {
                        if (!dettaglioPage) {
                            if (!isPageChanged) {
                                navigate(-1);
                            } else {
                                toggleModalIndietro(!isOpenIndietro);
                                if (isOpenIndietro) {
                                    navigate(-1);
                                }
                            }
                        } else {
                            navigate(-1);
                        }
                    }} id='indietro_btn'>INDIETRO</Button>
                    <Modal
                        isOpen={isOpenIndietro}
                        toggle={() => toggleModalIndietro(!isOpenIndietro)}
                    >
                        <ModalHeader toggle={() => toggleModalIndietro(!isOpenIndietro)} id='esempio12'>
                            Indietro
                        </ModalHeader>
                        <ModalBody>
                            <p>Tutte le modifiche andranno perse. Sei sicuro di voler continuare?</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button className="buttonPrimary" onClick={() => toggleModalIndietro(!isOpenIndietro)}>
                                Annulla
                            </Button>
                            <Button className="buttonPrimary" onClick={() => navigate(-1)}>
                                Conferma
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Col>
                {!dettaglioPage &&
                    <Col xs={10} sm={8} lg={3} xl={2} style={{ display: "flex", margin: "5px 0px" }}>
                        <Button
                            className="buttonPrimary"
                            onClick={() => {
                                if (!isPageChanged) {
                                    confirmSalvaClick()
                                } else {
                                    toggleModalSalva(true)
                                }
                            }}
                            id='salva_btn'>SALVA</Button>
                        <Modal
                            isOpen={isOpenSalva}
                            toggle={() => toggleModalSalva(!isOpenSalva)}
                        >
                            <ModalHeader toggle={() => toggleModalSalva(!isOpenSalva)} id='esempio12'>
                                Conferma
                            </ModalHeader>
                            <ModalBody>
                                <p>Confermi il salvataggio delle modifiche?</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button className="buttonPrimary" onClick={() => toggleModalSalva(false)}>
                                    Annulla
                                </Button>
                                <Button className="buttonPrimary" onClick={confirmSalvaClick}>
                                    Conferma
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Col>}
            </Row>
        </Container>
        <LinkList>
            <LinkListItem divider />
        </LinkList>
    </Container>
    )
}

export default GestioneProfilo;