import axios from 'axios';

const BASE_ENDPOINT = window._env_.BASE_ENDPOINT;

const getProfili = (payload) => {
    return axios.post(`${BASE_ENDPOINT}/v1/tipologiche/profili`,payload);
};

const getAziende = (payload) => {
    return axios.post(`${BASE_ENDPOINT}/v1/tipologiche/aziende`,payload);
};

const getStrutture = (payload) => {
    return axios.post(`${BASE_ENDPOINT}/v1/tipologiche/strutture`,payload);
};

const getPresidi = (payload) => {
    return axios.post(`${BASE_ENDPOINT}/v1/tipologiche/presidi`,payload);
};
export default {
    getProfili,
    getAziende,
    getStrutture,
    getPresidi,
};